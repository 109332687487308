import { _ } from "vue-underscore";

export default {
    data: () => ({
        content: '',
        backUrl: '/billing-countries',
        billingCountry: {
            id: 0,
            country_name: "",
            country_code: "",

        },
    }),

    methods: {
        validateBillingCountry() {
            this.billingCountry.id > 0 ? this.update() : this.add();
        },
        update() {
            let _vm = this;
            // Send a POST request to update the billing country
            this.axios
                .post("/billing-countries/" + this.billingCountry.id, {
                    ...this.billingCountry,
                    _method: "PATCH"
                })
                .then(function (response) {
                    // Update the billing country data
                    _vm.billingCountry = response.data.data;
                    // Redirect to the specified back URL
                    _vm.$router.push(_vm.backUrl);
                })
                .catch(function () {
                    // Handle any errors here
                });
        },

        add() {
            let _vm = this;
            // Sending a POST request to "/billing-countries" to add a billing country
            this.axios
                .post("/billing-countries", _vm.billingCountry)
                .then(function (response) {
                    _vm.$router.push(_vm.backUrl);
                })
                .catch(function () { });
        },
        getDetail() {
            let _vm = this;
            // Make a GET request to fetch billing country details
            this.axios
                .get("/billing-countries/" + this.$route.params.id)
                .then(function (response) {
                    _vm.billingCountry = response.data.data;
                })
                .catch(function () { });
        },
    },
    mounted() {
        if (this.$route.params.id > 0) {
            this.getDetail();
        }
    }
};
